import { useAppStore } from '~/store/app'
import { seoApi } from '~/api/seo'

export const useContact = () => {
  const { $axios, $pinia } = useNuxtApp()
  const { BRAND_CONTACT } = seoApi()
  const contactList = ref<any>([])
  const fetchContact = async () => {
    try {
      const { data: response } = await $axios.get(BRAND_CONTACT)
      if (response && response.status === 'OK') {
        if (response.data?.length > 0) {
          contactList.value = response.data
        }
      }
    } catch (error: any) {
      console.log('useContact', error)
    }
  }

  const openLiveChat = async () => {
    const openNewTab = window.open('about:blank', '_blank')

    if (contactList.value.length <= 0) {
      await fetchContact()
    }

    const livechatContact = contactList.value.find((contact: { name: string }) => contact.name === 'Live Chat')

    if (livechatContact && openNewTab) {
      openNewTab.location.href = livechatContact.url
    } else {
      console.error('Failed to open new tab or livechat contact not found')
    }
  }

  return {
    fetchContact,
    openLiveChat,
    contactList
  }
}
